<template>
  <div class="right">
  <div class="yue">
            <span>账户余额</span>
            <div class="yue-list">
              <h2 style="width: 100px; height: 40px; line-height: 40px">
                {{ yue }}
              </h2>
              <div style="width: 100px; margin-right: 10px">
                <el-button
                  type="danger"
                  @click="TixianTap"
                  style="width: 100px; background: red; border: red"
                  >提现</el-button
                >
              </div>
            </div>
          </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- <el-tab-pane label="账户余额" name="first">
        <div v-show="tab == 0">
          <div style="width: 100px; margin-top: 27px; font-size: 16px">
            提现记录
          </div>
          <div style="margin-top: 27px">
            <div class="title">
              <div class="container_table">
                <el-table
                  :header-cell-style="headeRowClass"
                  :data="tableData"
                  stripe
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                >

                  <el-table-column label="申请提现时间"  show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="ordertable">
                        <div class="ygdleft">
                          <p class="biaoti">{{ scope.row.withdrawTime }}</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="提现时间"  show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="ordertable">
                        <div class="ygdleft">
                          <p class="biaoti">{{ scope.row.handletime }}</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="提现金额"  show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="ordertable">
                        <div class="ygdleft">
                          <p class="biaoti">{{ scope.row.num }}</p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="提现状态"  show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="ordertable">
                        <div class="ygdleft">
                          <p class="biaoti">{{ scope.row.withdrawStateString }}</p>
                      
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
                <el-pagination
                  class="fy"
                  small
                  @size-change="handleSizeChange"
                  :page-size="pagesize"
                  :page-sizes="[10, 20, 30]"
                  layout="total, sizes, prev, pager, next, jumper"
                  @current-change="current_change"
                  :total="total"
                  background
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
        <div v-show="tab == 1">
          <TiXian></TiXian>
        </div>
      </el-tab-pane> -->
      <el-tab-pane name="second">
        <span slot="label">退款申请</span>
        <div v-if="orderlistlength1 != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist1"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="文章标题" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
<!--                      <p>-->
<!--                        <span>{{ scope.row.name }}</span-->
<!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
<!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="退款申请时间" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.applytime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="退款时间"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.handletime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="退款金额"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.refund_amount }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="退款状态"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.isdhandleString }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div>
						<div class="orderlist" v-for="(item,key) in orderlist1" @click="showDetail(item.id)">
							<div class="ygdleft">
								<p class="biaoti">{{item.title}}</p>
								<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
							</div>
							<div class="ygdright">
								<div class="urgent" v-show="item.attribute==1">
									加急
								</div>
								<div style="height: 20px;"></div>
								<span> <i class="el-icon-s-promotion"></i>投稿时间：{{item.Contribution_time}}</span>
							</div>
						</div>
					</div -->
            <div
              class="ygdPage"
              style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                class="fy"
                small
                @size-change="handleSizeChange1"
                :page-size="pagesize1"
                :page-sizes="[10, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="current_change1"
                :total="total1"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
       <el-tab-pane name="third">
        <span slot="label">提现申请</span>
        <div v-if="orderlistlength1 != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist2"
              tooltip-effect="dark"
              style="width: 100%"
            >


              <el-table-column label="申请提现时间"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.withdrawTime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提现时间"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.handletime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="提现金额"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.num }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="提现状态"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.withdrawStateString }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            class="ygdPage"
            style="
              display: flex;
              justify-content: center;
              width: 100%;
              margin-top: 20px;
            "
          >
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange2"
              :page-size="pagesize2"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change2"
              :total="total2"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { strBalance, TxHistory } from '../../../API/ZZD/storage'
import { costManager } from "../../../API/ZZD/costManager.js"

import TiXian from './Tixian'
export default {
  components: {
    TiXian
  },
  data () {
    return {
      activeName: 'second', //默认选项卡初始化1
      yue: '', // 余额
      years: '',
      nian: '',// 年
      tab: 0,//0 列表   1提现页面
      total: 0,//默认数据总数
      pagesize: 10,//每页的数据条数
      currentPage: 1,//默认开始页面
      tableData: [], // 提现记录列表
      //退款详情
      orderlistlength1: 0,
      total1:0,
      orderlist1:[],
      pagesize1:10,
      // 提现
      orderlistlength2:0,
      pagesize2:10,
      orderlist2:[],
      total2: 0, 
      currentPage2: 1, 
    };
  },
  mounted () {
    this.balanced(); /*初始化数据都叫这个名字*/
    this.BalancedList();
    this.payorder()
    this.juorder()
  },

  methods: {
    juorder () {
      var id = localStorage.getItem('ZZId');
      costManager(id, this.currentPage2, this.pagesize2, 2).then(res => {
        console.log(res)
        this.total2 = res.data.total
        this.orderlist2 = res.data.data
        this.orderlistlength2 = this.orderlist2.length
        console.log(this.orderlist2)
      })
    },
    payorder () {

      var id = localStorage.getItem('ZZId');
      costManager(id, this.currentPage, this.pagesize, 1).then(res => {

        console.log(res,'idididididiidididzuozhe')
        this.total1 = res.data.total

        this.orderlist1 = res.data.data
       // alert(res.data.data);
        this.orderlistlength1 = this.orderlist1.length

      })
    },
    handleClick (tab, event) {
      console.log(tab.index);
    },
    // 更改表头样式
    headeRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    current_change1: function (currentPage) {
      this.currentPage = currentPage;
      this.payorder()
    },
    current_change2: function (currentPage) {
      this.currentPage2 = currentPage;
      this.juorder()
    },
    handleSizeChange2 (val) {
      console.log(val);
      this.pagesize2 = val;
      this.juorder()
    }, // 分页
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.BalancedList()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.BalancedList();
    },handleSizeChange1 (val) {
      console.log(val);
      this.pagesize1 = val;
      this.payorder()
    },
    // 提现
    TixianTap () {
      this.$router.push(
        {
          path: "/Pending/withdrawal?id=4",
          query: {

            "nums": this.yue
          }
        })
    },
    // 查询余额接口
    balanced () {
      let id = localStorage.getItem('ZZId')

      strBalance(id).then(res => {
        this.yue = res.data.data
      })
    },
    // 提现记录列表
    BalancedList () {
      let id = localStorage.getItem('ZZId')

      costManager(id, this.currentPage, this.pagesize,2).then(res => {
        this.tableData = res.data.data
        console.log(res)
        this.total = res.data.total
      })
    },

  },
};
</script>

<style  scoped>
.yue {
  width: 100%;
  height: 110px;
}
.yue-list {
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.fy {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 27px;
}
.orderlist {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.biaoti {
  font-size: 14px;
}

.orderlist span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0 3px;
}

.urgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: #ff1918;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.nourgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: lightseagreen;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.who {
  position: absolute;
  right: 50px;
  top: 0;
  width: 40px;
  height: 24px;
  background: #2f7df7;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 0 5px;
}

.ygdPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ygdright {
  width: 100rpx;
}
</style>